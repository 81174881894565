import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import headerSection from "../../assets/images/headerSection.jpg";
import Logo from "../../assets/images/logo.png";
import { getAboutUsAction } from "../../Store/actions/HomeAction";
import { useOnMount } from "../../utils/helpers";

const AboutUs = () => {
  const dispatch = useDispatch();
  const { about_us } = useSelector((state) => state.home.about);
  useOnMount(() => {
    dispatch(getAboutUsAction());
  });
  return (
    <>
      <img className="header-section" src={headerSection} alt="header-poster" />
      <section className="section-3">
        <div className="header-contain">
          <div className="header">About us</div>
          <div className="sub-header">About us</div>
        </div>
        <Row>
          {/* <Col className="text-center" xs={12}>
            <img className="w-50 py-5" src={Logo} alt="eng" />
          </Col> */}
          <Col className="text-center p-4" xs={12}>
          <div dangerouslySetInnerHTML={{__html: about_us}} />
            {/* {about_us} */}
          </Col>
        </Row>
      </section>
    </>
  );
};

export default AboutUs;
