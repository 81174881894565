/* eslint-disable no-nested-ternary */
import { put, select } from "redux-saga/effects";
import { getAllScheduleList, getAllScoreSheet, getHighlightListApi, getTeamList, getTeamPlayer, getTeamScore ,getLeagueStandings,getLeagueTeamList,getLeagueStatistics,getScheduleStats, getPlayerStats,  getPlayerDetails} from "../../api";
import { setLoading } from "../actions/loading";
import { clearError } from "../actions/error";

import DummyImage from '../../assets/images/dummy-image.jpg'
import avtarimg from '../../assets/images/avatar.png'



import { handleException } from "../../utils/helpers";
import { SetHighLightsAction, setLeagueListAction, setLeagueScheduleDetailAction, setTeamList, setTeamPlayer, setTeamScore ,setLeagueStandingsAction,setLeagueTeamList,setLeagueStatsAction,setScheduleStatsAction, setPlayerStatsAction,setPlayerDetailAction} from "../actions/leagueAction";
// import { setPlayerDetailsAction } from "../actions/HomeAction";
// import { setPlayerDetailsAction } from "../actions/HomeAction";

export function* fetchLeagueListSaga() {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getAllScoreSheet();
    // console.log("ressss",response);
    const leagueList = response?.data?.data;
     let LeagueListupd=leagueList.map((item)=>{
    // console.log("iii",item);
    if(item.gender==1){
      return {...item, gender: 'Male'};
    }else if(item.gender==2){
      return {...item, gender: 'Female'};
    }else{
      return {...item, gender: 'Mixed'};

    }
    return item;
  })
    // console.log("lll1112222",leagueList);
    yield put(setLeagueListAction(LeagueListupd));
    // yield put(setLeagueListAction(leagueList));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "leagueList");
  }
}
export function* fetchLeagueScheduleDetailSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getAllScheduleList(action.payload);
    // console.log("vrr",response);
    const leagueScheduleDetails = response?.data?.data;
    let scheduleList=leagueScheduleDetails.map((item)=>{
      // console.log("iii000000",item);
      if(item.is_match_started==null){
        return {...item, status: 'Did not start'};
      }else if(item.is_match_completed==0){
        return {...item, status: 'In Progress'};
      }else{
        return {...item, status: 'Completed'};
  
      }
      return item;
    })
  
    let scorechng=scheduleList.map(v => ({...v, score: `(H) ${ v.home_team_score} - (V) ${ v.visitor_team_score}`}))
    // yield put(setLeagueScheduleDetailAction(leagueScheduleDetails));
    yield put(setLeagueScheduleDetailAction(scorechng));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "leagueScheduleDetails");
  }
}

export function* fetchLeagueViewStatusSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const teamList = yield getTeamList(action.payload);
    const teamPlayer = yield getTeamPlayer(action.payload);
    const teamScore = yield getTeamScore(action.payload);
    const list = teamList?.data?.data;
    const players = teamPlayer?.data?.data;
    const score = teamScore?.data?.data;
    yield put(setTeamList(list));
    yield put(setTeamPlayer(players));
    yield put(setTeamScore(score));

    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "leagueScheduleDetails");
  }
}

export function* fetchHighLightListSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const highlight = yield getHighlightListApi(action.payload);
    const list = highlight?.data?.data;
    yield put(SetHighLightsAction(list));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "highLight List");
  }
}


//league standing

export function* fetchLeagueStandingsSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getLeagueStandings(action.payload);
    // console.log("standingsssss",response);
    const leagueStandingDetails = response?.data?.data;
    let item=leagueStandingDetails.map(v=>({
      ...v,
      pool_name:v.pool.name,
      leagueId:v.pool.leagueId
   }))
   const res=item.map(nested =>({ pool:nested.pool, teamList:
    nested.teamList.map(menu =>({...menu,pool_name:nested.pool_name,leagueId:nested.leagueId})) }));
    let arr=[]
for (const obj of res) {
  for (const team of obj.teamList) {
    // console.log("ttt",team);
    // arr.push(team)
    arr.push({
      pool_name: team.pool_name,
      leagueId:team.leagueId,
      team_name: team.team_name,
      team_town: team.team_town,
      match_schedules: team.wins + team.lost,
      record: "(W) " + team.wins + " - " + " (L) " + team.lost,
      points_for: team.points_for,
      points_against: team.points_against,
      // per: item.wins,
      per:
        team.wins + team.lost != 0
          ? ((team.wins / (team.wins + team.lost)) * 100).toFixed(1)
          : (0).toFixed(1),
    })
  }
}
    yield put(setLeagueStandingsAction(arr));
    // yield put(setLeagueStandingsAction(leagueStandingDetails));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "leagueStandingDetails");
  }
}

//league teams list

export function* fetchLeagueTeamsSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getLeagueTeamList(action.payload);
    // console.log("teammmmm",response);
    const leagueTeamDetails = response?.data?.data;
    let finaltem = leagueTeamDetails.map((item) => 
    Object.assign({}, item.team_detail, {teamid:item.id})
    )
    const newUsers = finaltem.map((user) => ({
      ...user,

      gender: user.gender == 1 ? "Male" : user.gender == 2 ? "Female" : user.gender == 3 ? "Mixed" :"",
    //   logo:   <img
    //   src={`${process.env.REACT_APP_API_ENDPOINT}${user.logo}`}
    //   height={75}
    //   width={75}
    // />
  
    logo:!user.logo ? (
      <img
        src={DummyImage}
        height={75}
        width={75}
        // onClick={() => handleImage(DummyImage)}
      />
    ) : (
      <img
        src={`${process.env.REACT_APP_API_ENDPOINT}${user.logo}`}
        height={75}
        width={75}
      
      />
    )
    }));
  
    yield put(setLeagueTeamList(newUsers));
    // yield put(setLeagueTeamList(leagueTeamDetails));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "leagueTeamListDetails");
  }
}

//league stats list

export function* fetchLeagueStatsSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getLeagueStatistics(action.payload);
    // console.log("lsts",response);
    const leagueStatsDetails = response?.data;
    let playerlist=leagueStatsDetails.playerList
    let arr=[]
    // const scoredata=(stats)=>{
      // let finalst=[]
      // finalst.push(stats)
      // console.log("hjhjh",finalst);
      // console.log("sttttaaattss",stats);
      // let arr2=finalst[0].playerList
    playerlist.forEach((element,i) => {
      // console.log("EEE",element);
      arr.push({
        // id: element.id,
        ranking:i+1,
        logo:( element.avtar!=null && element.avtar!="" ?
          <img
            src={`${process.env.REACT_APP_API_ENDPOINT}${element.avtar}`}
            height={75}
            width={75}
          
          />
          :
          <img
          src={`${avtarimg}`}
          height={75}
          width={75}
        
        />
        ),
        player_id:element.id,
        first_name: element.first_name,
        last_name: element.last_name,
        name: element.team.name,
        town: element.team.town,
        level: element.team.level,
        G: element.score.G,
        PPG:  isNaN(Number(element.score.PPG))
        ? element.score.PPG == "NA"
          ? element.score.PPG
          : 0
        : Number(element.score.PPG),
        // RPG: isNaN(Number(element.score.RPG)) ? 0 : element.score.RPG,
        RPG: isNaN(Number(element.score.RPG))
        ? element.score.RPG == "NA"
          ? element.score.RPG
          : 0
        : Number(element.score.RPG),
        OFF:  isNaN(Number(element.score.OFF))
        ? element.score.OFF == "NA"
          ? element.score.OFF
          : 0
        : Number(element.score.OFF),
        DFE:  isNaN(Number(element.score.DFE))
        ? element.score.DFE == "NA"
          ? element.score.DFE
          : 0
        : Number(element.score.DFE),
        APG:  isNaN(Number(element.score.APG))
        ? element.score.APG == "NA"
          ? element.score.APG
          : 0
        : Number(element.score.APG),
        FT: isNaN(Number(element.score.FT))
        ? element.score.FT == "NA"
          ? element.score.FT
          : 0
        : Number(element.score.FT),
        FG:  isNaN(Number(element.score.FG))
        ? element.score.FG == "NA"
          ? element.score.FG
          : 0
        : Number(element.score.FG),
        // P_3: isNaN(Number(element.score["3P"])) ? 0 : element.score["3P"],
        P_3: isNaN(Number(element.score["3P"]))
        ? element.score["3P"] == "NA"
          ? element.score["3P"]
          : 0
        : Number(element.score["3P"]),
        P_2: isNaN(Number(element.score["2P"]))
        ? element.score["2P"] == "NA"
          ? element.score["2P"]
          : 0
        : Number(element.score["2P"]),
      });
    });
    // return arr;
    // console.log("finalarr",arr);
  // }
    // yield put(setLeagueStatsAction(leagueStatsDetails));
    yield put(setLeagueStatsAction(arr));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "leagueStatsDetails");
  }
}


//league schedule stats list

export function* fetchScheduleStatsSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getScheduleStats(action.payload);
    // console.log("teammmmm",response);
    const scheduleStatsDetails = response?.data;
    yield put(setScheduleStatsAction(scheduleStatsDetails));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "scheduleStatsDetails");
  }
}


//player details & stats

export function* fetchPlayerStatsSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getPlayerStats(action.payload);
    // console.log("psr",response);
    let arr=[]
    const playerStats = response?.data?.playerList;
  //   let finaldata=playerStats.map(item=>({
  //     ...item.score,
  //     match_id:item.match_id,
  //     teamname:item.team.team_detail.name,
  //     level:item.team.team_detail.level
  //  }))
  //  console.log("ffdd",finaldata);
  //  let dat2=finaldata.forEach((item)=>{
   let pdata=playerStats.forEach((item)=>{
    arr.push({
       level:item.team.team_detail.level,
       match_id:item.match_id,
       team_name:item.team.team_detail.name,
       town:item.team.team_detail.town,
       G:item.score.G,
       PPG:isNaN(Number(item.score.PPG))
       ? 0
       : Number(item.score.PPG),
       RPG:isNaN(Number(item.score.RPG))
       ? 0
       : Number(item.score.RPG),
       OFF:isNaN(Number(item.score.OFF))
       ? 0
       : Number(item.score.OFF),
       APG:isNaN(Number(item.score.APG))
       ? 0
       : Number(item.score.APG),
       FG:isNaN(Number(item.score.FG))
       ? 0
       : Number(item.score.FG),
       FT:isNaN(Number(item.score.FT))
       ? 0
       : Number(item.score.FT),
       P_2:isNaN(Number(item.score["2P"]))
       ? 0
       : Number(item.score["2P"]),
       P_3:isNaN(Number(item.score["3P"]))
       ? 0
       : Number(item.score["3P"]),
       DFE:isNaN(Number(item.score.DFE))
       ? 0
       : Number(item.score.DFE),

      })
    })
    // console.log("ffdd2",arr);
    yield put(setPlayerStatsAction(arr));
    // yield put(setPlayerStatsAction(finaldata));
    // yield put(setPlayerStatsAction(playerStats));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "playerStats");
  }
}

export function* fetchPlayerDetailSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getPlayerDetails(action.payload);
    // console.log("prrrrr",response);
    const playerDetails = response?.data?.data?.player_detail;
    yield put(setPlayerDetailAction(playerDetails));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "playerDetails");
  }
}