import React ,{useState} from "react";
import { Col, Nav, Navbar, Row } from "react-bootstrap";
import googleIcon from "../../assets/images/google.svg";
import facebookIcon from "../../assets/images/facebook.svg";
import twitterIcon from "../../assets/images/twitter.svg";
import linkedIcon from "../../assets/images/linkedin.svg";
import Logo from "../../assets/images/logo.png";
import "./Footer.scss";
import { Divider, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import firstCard from "../../assets/images/firstcard.svg";
import secondCard from "../../assets/images/secondcard.svg";
import thirdCard from "../../assets/images/thirdcard.svg";
import fourthCard from "../../assets/images/fourthcard.svg";
import fifthCard from "../../assets/images/fifthcard.svg";
import sixthCard from "../../assets/images/sixthcard.svg";
import seventhCard from "../../assets/images/seventhcard.svg";
import eightCard from "../../assets/images/eightcard.svg";
import ninthCard from "../../assets/images/ninthcard.svg";
import arrow from '../../assets/images/arrow.svg'
import { useHistory } from 'react-router';
import { useOnMount } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { sendEmailSubscription } from "../../Store/actions/HomeAction";
import CustomeNotification from "../CustomeNotification/CustomeNotification";






const Footer = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const [emailsubs, setEmailSubs] = useState({
    email: ""
  });
  const [isError, setIsError] = useState({});


  const validateEmail = (email) => {
    const emailRegex =
      /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (email && emailRegex.test(email.trim())) {
      return true;
    }
    return false;
  };

  const validateform = (values) => {
    let errors = {};

  
    // if (!values.last_name) {
    //   errors.last_name = "Last name is required";
    // }
    // if (values.last_name.trim() == "") {
    //   errors.last_name = "Last name is required";
    // }
    if (!values.email && values.email.trim() == "" ) {
      errors.email = "Email is required";
    } else if (!validateEmail(values.email)) {
      errors.email = "Please enter a valid email";
    }
    return errors;
  };

  const handleFocus = (e) => {
    const validation = validateform(emailsubs);
    setIsError(validation);
    // setIsFocus({ ...isFocus, [e.target.name]: true });
  };
  // useOnMount(() => {
 
  //   dispatch(sendEmailSubscription({
  //     "email":"xyzaa@yopmail.com"
  //   }))
  // });
  const handleChangeInput = (e) => {
    setEmailSubs({
      ...emailsubs,
      [e.target.name]: e.target.value,
    });
    setIsError({
      ...isError,
      [e.target.name]: "",
    });
    // setIsError({
    //   ...isError,
    //   [e.target.name]: "",
    // });
  };
  const onSubmitForm = (e) => {
    e.preventDefault();
    const validation = validateform(emailsubs);
    if (Object.keys(validation).length > 0) {
  
      CustomeNotification("error","error" ,"Please enter Valid Email", 2000);
      setIsError(validation);
      return;
    }
    // setOpen(true)
    // const validation = validateform(contact);
    // if (Object.keys(validation).length > 0) {
      // console.log("eee");
    //   <>
    //   <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
    //   <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
    //     This is a success message!
    //   </Alert>
    // </Snackbar>
    // <Alert severity="success">This is a success message!</Alert> </>
    //   CustomeNotification("error","error" ,"Please Fill All Data", 2000);
    //   setIsError(validation);
    //   return;
    // }
     else {
      // console.log("hii");
      // dispatch(sendContactDetails(contact));
      // dispatch(sendContact(contact));
      dispatch(sendEmailSubscription(emailsubs))
      
     
      setEmailSubs({
        email: ""
      })
    }
  };
  return (
    <>
  
      {/* <div className="footer footer-option bg-dark pb-2 pt-3"> */}
      {/* <div className="container-fluid">
          <Row>
            <Col sm={12} className="text-center text-md-center">
              <img src={Logo} className="company-logo" alt="logo" />

              <Col sm={12} className="text-center text-md-center mt-5 mt-md-0 footer-nav">
                <ul className="d-flex justify-content-between w-25 mt-3 mx-auto text-center" >
                  <li>
                    <Link className="nav-footer-option" to="/about-us">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-footer-option" to="/faq">
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-footer-option" to="/privacy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-footer-option" to="/terms-of-use">
                      Terms of use
                    </Link>
                  </li>
                </ul>
              </Col>

              <div className="mt-3">
                <div className="mail text-org">Address:</div>
                <div className="mail-info">1321, will St. Montreal, Quebec, K2B H9A, CANADA </div>
              </div>
              <div className="mt-3">
                <div className="mail text-org">Email:</div>
                <div className="mail-info"> info@hooplivestats.com</div>
              </div>
              <div className="mt-3">
                <div className="mail text-org">Social Media:</div>
                <div className="mail-info mt-3">
                  <img className="social-icon pl-0 mx-2" src={googleIcon} alt="icon" />
                  <img className="social-icon pl-0 mx-2" src={facebookIcon} alt="icon" />
                  <img className="social-icon pl-0 mx-2" src={twitterIcon} alt="icon" />
                  <img className="social-icon pl-0 mx-2" src={linkedIcon} alt="icon" />
                </div>
              </div>

            </Col>


          </Row>
        </div> */}
      {/* </div> */}
      {/* <div className="footer">
        <Navbar className="footer-option">
          <Link to="/">
            <img src={Logo} className="company-logo" alt="logo" />
          </Link>
          <Grid container spacing={0}>
            <Grid item xs={9}>
              <div className="d-flex justify-content-center">
                <Link className="nav-footer-option" to="/about-us">
                  About us
                </Link>
                <Link className="nav-footer-option" to="/story">
                  News
                </Link>
                <Link className="nav-footer-option" to="/">
                  Privacy Policy
                </Link>
                <Link className="nav-footer-option" to="/">
                  Terms of use
                </Link>
              </div>
            </Grid>
            <Grid item xs={3} className="d-flex py-2">
              <div className="mail d-flex text-org">Email:</div>
              <div className="mail-info"> info@hooplivestats.com</div>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={9}>
              <div className="d-flex justify-content-center">
                <Nav.Link className="nav-footer-option">
                  1321, will St. Montreal, Quebec, K2B H9A, CANADA
                </Nav.Link>
              </div>
            </Grid>
            <Grid item xs={3} className="d-flex py-2">
              <div className="mail d-flex text-org">Social Media:</div>
              <div className="mail-info">
                <img className="social-icon" src={googleIcon} alt="icon" />
                <img className="social-icon" src={facebookIcon} alt="icon" />
                <img className="social-icon" src={twitterIcon} alt="icon" />
                <img className="social-icon" src={linkedIcon} alt="icon" />
              </div>
            </Grid>
          </Grid>
        </Navbar>
        <Navbar bg="dark" className="last-footer" variant="dark">
          <div>Copyrights 2021 | hoopLiveStats</div>
          <div>All Rights Reserved</div>
        </Navbar>
      </div> */}
      {/* <div className="mob-view"> */}
      <div className="footer-sec1">
        <h1 className="head1">SUBSCRIBE TO OUR EMAILS</h1>
        <p className="foot-p">Stay connected and learn more about us. </p>
        <form className="form">
                  
        <input type="email" placeholder="Email"  className="email-inp" id="email" name="email" value={emailsubs.email} 

onChange={(e) => handleChangeInput(e)} />
        <button type="submit" onClick={onSubmitForm} className="newsletter-form__button field__button button" name="commit" id="Subscribe" aria-label="Subscribe" fdprocessedid="i3uda8">
                    <svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" role="presentation" className="icon icon-arrow arr-icon" xmlns="http://www.w3.org/2000/svg" >
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor">
</path></svg>

                  </button>
                  </form>
      </div>
      {/* <div className="row footer-sec2">
        <div className="col-md-4">
          <p>About Us</p>
          <p>Search</p>
        </div>
        <div className="col-md-4">
          <img src={Logo} alt="logo" width={"150px"} height={"40px"} />
        </div>
        <div className="col-md-4">
          <p>
            Share contact information, store details, and brand content with
            your customers.
          </p>
        </div>
      </div> */}
        <div className="footer-sec2">
        <div className="col">
          <p>About Us</p>
          <p>Search</p>
        </div>
        <div className="col d-flex justify-content-center">
          <img src={Logo} alt="logo" width={"150px"} height={"40px"} />
        </div>
        <div className="col">
          <p>
            Share contact information, store details, and brand content with
            your customers.
          </p>
        </div>
      </div>
      {/* <hr className="line" /> */}
      <div className="footer-sec3">
 

        <img src={firstCard} alt="Card"  className="svgimg"/>
        <img src={secondCard} alt="Card" className="svgimg"/>
        <img src={thirdCard} alt="Card"  className="svgimg"/>
        <img src={fourthCard} alt="Card"  className="svgimg"/>
        <img src={fifthCard} alt="Card"  className="svgimg"/>
        <img src={sixthCard} alt="Card"  className="svgimg"/>
        <img src={seventhCard} alt="Card"  className="svgimg"/>
        <img src={eightCard} alt="Card"  className="svgimg"/>
        <img src={ninthCard} alt="Card"  className="svgimg"/>
        {/* <img src={ninthCard} alt="Card" width={"45px"} className="svgimg"/> */}

        {/* <svg
          class="icon icon--full-color"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          x="0"
          y="0"
          width="38"
          height="24"
          viewBox="0 0 165.521 105.965"
          // xml:space="preserve"
          aria-labelledby="pi-apple_pay"
        >
          <title id="pi-apple_pay">Apple Pay</title>
          <path
            fill="#000"
            d="M150.698 0H14.823c-.566 0-1.133 0-1.698.003-.477.004-.953.009-1.43.022-1.039.028-2.087.09-3.113.274a10.51 10.51 0 0 0-2.958.975 9.932 9.932 0 0 0-4.35 4.35 10.463 10.463 0 0 0-.975 2.96C.113 9.611.052 10.658.024 11.696a70.22 70.22 0 0 0-.022 1.43C0 13.69 0 14.256 0 14.823v76.318c0 .567 0 1.132.002 1.699.003.476.009.953.022 1.43.028 1.036.09 2.084.275 3.11a10.46 10.46 0 0 0 .974 2.96 9.897 9.897 0 0 0 1.83 2.52 9.874 9.874 0 0 0 2.52 1.83c.947.483 1.917.79 2.96.977 1.025.183 2.073.245 3.112.273.477.011.953.017 1.43.02.565.004 1.132.004 1.698.004h135.875c.565 0 1.132 0 1.697-.004.476-.002.952-.009 1.431-.02 1.037-.028 2.085-.09 3.113-.273a10.478 10.478 0 0 0 2.958-.977 9.955 9.955 0 0 0 4.35-4.35c.483-.947.789-1.917.974-2.96.186-1.026.246-2.074.274-3.11.013-.477.02-.954.022-1.43.004-.567.004-1.132.004-1.699V14.824c0-.567 0-1.133-.004-1.699a63.067 63.067 0 0 0-.022-1.429c-.028-1.038-.088-2.085-.274-3.112a10.4 10.4 0 0 0-.974-2.96 9.94 9.94 0 0 0-4.35-4.35A10.52 10.52 0 0 0 156.939.3c-1.028-.185-2.076-.246-3.113-.274a71.417 71.417 0 0 0-1.431-.022C151.83 0 151.263 0 150.698 0z"
          ></path>
          <path
            fill="#FFF"
            d="M150.698 3.532l1.672.003c.452.003.905.008 1.36.02.793.022 1.719.065 2.583.22.75.135 1.38.34 1.984.648a6.392 6.392 0 0 1 2.804 2.807c.306.6.51 1.226.645 1.983.154.854.197 1.783.218 2.58.013.45.019.9.02 1.36.005.557.005 1.113.005 1.671v76.318c0 .558 0 1.114-.004 1.682-.002.45-.008.9-.02 1.35-.022.796-.065 1.725-.221 2.589a6.855 6.855 0 0 1-.645 1.975 6.397 6.397 0 0 1-2.808 2.807c-.6.306-1.228.511-1.971.645-.881.157-1.847.2-2.574.22-.457.01-.912.017-1.379.019-.555.004-1.113.004-1.669.004H14.801c-.55 0-1.1 0-1.66-.004a74.993 74.993 0 0 1-1.35-.018c-.744-.02-1.71-.064-2.584-.22a6.938 6.938 0 0 1-1.986-.65 6.337 6.337 0 0 1-1.622-1.18 6.355 6.355 0 0 1-1.178-1.623 6.935 6.935 0 0 1-.646-1.985c-.156-.863-.2-1.788-.22-2.578a66.088 66.088 0 0 1-.02-1.355l-.003-1.327V14.474l.002-1.325a66.7 66.7 0 0 1 .02-1.357c.022-.792.065-1.717.222-2.587a6.924 6.924 0 0 1 .646-1.981c.304-.598.7-1.144 1.18-1.623a6.386 6.386 0 0 1 1.624-1.18 6.96 6.96 0 0 1 1.98-.646c.865-.155 1.792-.198 2.586-.22.452-.012.905-.017 1.354-.02l1.677-.003h135.875"
          ></path>
          <g>
            <g>
              <path
                fill="#000"
                d="M43.508 35.77c1.404-1.755 2.356-4.112 2.105-6.52-2.054.102-4.56 1.355-6.012 3.112-1.303 1.504-2.456 3.959-2.156 6.266 2.306.2 4.61-1.152 6.063-2.858"
              ></path>
              <path
                fill="#000"
                d="M45.587 39.079c-3.35-.2-6.196 1.9-7.795 1.9-1.6 0-4.049-1.8-6.698-1.751-3.447.05-6.645 2-8.395 5.1-3.598 6.2-.95 15.4 2.55 20.45 1.699 2.5 3.747 5.25 6.445 5.151 2.55-.1 3.549-1.65 6.647-1.65 3.097 0 3.997 1.65 6.696 1.6 2.798-.05 4.548-2.5 6.247-5 1.95-2.85 2.747-5.6 2.797-5.75-.05-.05-5.396-2.101-5.446-8.251-.05-5.15 4.198-7.6 4.398-7.751-2.399-3.548-6.147-3.948-7.447-4.048"
              ></path>
            </g>
            <g>
              <path
                fill="#000"
                d="M78.973 32.11c7.278 0 12.347 5.017 12.347 12.321 0 7.33-5.173 12.373-12.529 12.373h-8.058V69.62h-5.822V32.11h14.062zm-8.24 19.807h6.68c5.07 0 7.954-2.729 7.954-7.46 0-4.73-2.885-7.434-7.928-7.434h-6.706v14.894z"
              ></path>
              <path
                fill="#000"
                d="M92.764 61.847c0-4.809 3.665-7.564 10.423-7.98l7.252-.442v-2.08c0-3.04-2.001-4.704-5.562-4.704-2.938 0-5.07 1.507-5.51 3.82h-5.252c.157-4.86 4.731-8.395 10.918-8.395 6.654 0 10.995 3.483 10.995 8.89v18.663h-5.38v-4.497h-.13c-1.534 2.937-4.914 4.782-8.579 4.782-5.406 0-9.175-3.222-9.175-8.057zm17.675-2.417v-2.106l-6.472.416c-3.64.234-5.536 1.585-5.536 3.95 0 2.288 1.975 3.77 5.068 3.77 3.95 0 6.94-2.522 6.94-6.03z"
              ></path>
              <path
                fill="#000"
                d="M120.975 79.652v-4.496c.364.051 1.247.103 1.715.103 2.573 0 4.029-1.09 4.913-3.899l.52-1.663-9.852-27.293h6.082l6.863 22.146h.13l6.862-22.146h5.927l-10.216 28.67c-2.34 6.577-5.017 8.735-10.683 8.735-.442 0-1.872-.052-2.261-.157z"
              ></path>
            </g>
          </g>
        </svg> */}

        {/* <svg
          class="icon icon--full-color"
          viewBox="0 0 38 24"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          width="38"
          height="24"
          aria-labelledby="pi-diners_club"
        >
          <title id="pi-diners_club">Diners Club</title>
          <path
            opacity=".07"
            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
          ></path>
          <path
            fill="#fff"
            d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
          ></path>
          <path
            d="M12 12v3.7c0 .3-.2.3-.5.2-1.9-.8-3-3.3-2.3-5.4.4-1.1 1.2-2 2.3-2.4.4-.2.5-.1.5.2V12zm2 0V8.3c0-.3 0-.3.3-.2 2.1.8 3.2 3.3 2.4 5.4-.4 1.1-1.2 2-2.3 2.4-.4.2-.4.1-.4-.2V12zm7.2-7H13c3.8 0 6.8 3.1 6.8 7s-3 7-6.8 7h8.2c3.8 0 6.8-3.1 6.8-7s-3-7-6.8-7z"
            fill="#3086C8"
          ></path>
        </svg>

        <svg
          class="icon icon--full-color"
          viewBox="0 0 38 24"
          width="38"
          height="24"
          role="img"
          aria-labelledby="pi-discover"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title id="pi-discover">Discover</title>
          <path
            fill="#000"
            opacity=".07"
            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
          ></path>
          <path
            d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32z"
            fill="#fff"
          ></path>
          <path
            d="M3.57 7.16H2v5.5h1.57c.83 0 1.43-.2 1.96-.63.63-.52 1-1.3 1-2.11-.01-1.63-1.22-2.76-2.96-2.76zm1.26 4.14c-.34.3-.77.44-1.47.44h-.29V8.1h.29c.69 0 1.11.12 1.47.44.37.33.59.84.59 1.37 0 .53-.22 1.06-.59 1.39zm2.19-4.14h1.07v5.5H7.02v-5.5zm3.69 2.11c-.64-.24-.83-.4-.83-.69 0-.35.34-.61.8-.61.32 0 .59.13.86.45l.56-.73c-.46-.4-1.01-.61-1.62-.61-.97 0-1.72.68-1.72 1.58 0 .76.35 1.15 1.35 1.51.42.15.63.25.74.31.21.14.32.34.32.57 0 .45-.35.78-.83.78-.51 0-.92-.26-1.17-.73l-.69.67c.49.73 1.09 1.05 1.9 1.05 1.11 0 1.9-.74 1.9-1.81.02-.89-.35-1.29-1.57-1.74zm1.92.65c0 1.62 1.27 2.87 2.9 2.87.46 0 .86-.09 1.34-.32v-1.26c-.43.43-.81.6-1.29.6-1.08 0-1.85-.78-1.85-1.9 0-1.06.79-1.89 1.8-1.89.51 0 .9.18 1.34.62V7.38c-.47-.24-.86-.34-1.32-.34-1.61 0-2.92 1.28-2.92 2.88zm12.76.94l-1.47-3.7h-1.17l2.33 5.64h.58l2.37-5.64h-1.16l-1.48 3.7zm3.13 1.8h3.04v-.93h-1.97v-1.48h1.9v-.93h-1.9V8.1h1.97v-.94h-3.04v5.5zm7.29-3.87c0-1.03-.71-1.62-1.95-1.62h-1.59v5.5h1.07v-2.21h.14l1.48 2.21h1.32l-1.73-2.32c.81-.17 1.26-.72 1.26-1.56zm-2.16.91h-.31V8.03h.33c.67 0 1.03.28 1.03.82 0 .55-.36.85-1.05.85z"
            fill="#231F20"
          ></path>
          <path
            d="M20.16 12.86a2.931 2.931 0 100-5.862 2.931 2.931 0 000 5.862z"
            fill="url(#pi-paint0_linear)"
          ></path>
          <path
            opacity=".65"
            d="M20.16 12.86a2.931 2.931 0 100-5.862 2.931 2.931 0 000 5.862z"
            fill="url(#pi-paint1_linear)"
          ></path>
          <path
            d="M36.57 7.506c0-.1-.07-.15-.18-.15h-.16v.48h.12v-.19l.14.19h.14l-.16-.2c.06-.01.1-.06.1-.13zm-.2.07h-.02v-.13h.02c.06 0 .09.02.09.06 0 .05-.03.07-.09.07z"
            fill="#231F20"
          ></path>
          <path
            d="M36.41 7.176c-.23 0-.42.19-.42.42 0 .23.19.42.42.42.23 0 .42-.19.42-.42 0-.23-.19-.42-.42-.42zm0 .77c-.18 0-.34-.15-.34-.35 0-.19.15-.35.34-.35.18 0 .33.16.33.35 0 .19-.15.35-.33.35z"
            fill="#231F20"
          ></path>
          <path
            d="M37 12.984S27.09 19.873 8.976 23h26.023a2 2 0 002-1.984l.024-3.02L37 12.985z"
            fill="#F48120"
          ></path>
          <defs>
            <linearGradient
              id="pi-paint0_linear"
              x1="21.657"
              y1="12.275"
              x2="19.632"
              y2="9.104"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F89F20"></stop>
              <stop offset=".25" stop-color="#F79A20"></stop>
              <stop offset=".533" stop-color="#F68D20"></stop>
              <stop offset=".62" stop-color="#F58720"></stop>
              <stop offset=".723" stop-color="#F48120"></stop>
              <stop offset="1" stop-color="#F37521"></stop>
            </linearGradient>
            <linearGradient
              id="pi-paint1_linear"
              x1="21.338"
              y1="12.232"
              x2="18.378"
              y2="6.446"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F58720"></stop>
              <stop offset=".359" stop-color="#E16F27"></stop>
              <stop offset=".703" stop-color="#D4602C"></stop>
              <stop offset=".982" stop-color="#D05B2E"></stop>
            </linearGradient>
          </defs>
        </svg>

        <svg
          class="icon icon--full-color"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          viewBox="0 0 38 24"
          width="38"
          height="24"
          aria-labelledby="pi-google_pay"
        >
          <title id="pi-google_pay">Google Pay</title>
          <path
            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
            fill="#000"
            opacity=".07"
          ></path>
          <path
            d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
            fill="#FFF"
          ></path>
          <path
            d="M18.093 11.976v3.2h-1.018v-7.9h2.691a2.447 2.447 0 0 1 1.747.692 2.28 2.28 0 0 1 .11 3.224l-.11.116c-.47.447-1.098.69-1.747.674l-1.673-.006zm0-3.732v2.788h1.698c.377.012.741-.135 1.005-.404a1.391 1.391 0 0 0-1.005-2.354l-1.698-.03zm6.484 1.348c.65-.03 1.286.188 1.778.613.445.43.682 1.03.65 1.649v3.334h-.969v-.766h-.049a1.93 1.93 0 0 1-1.673.931 2.17 2.17 0 0 1-1.496-.533 1.667 1.667 0 0 1-.613-1.324 1.606 1.606 0 0 1 .613-1.336 2.746 2.746 0 0 1 1.698-.515c.517-.02 1.03.093 1.49.331v-.208a1.134 1.134 0 0 0-.417-.901 1.416 1.416 0 0 0-.98-.368 1.545 1.545 0 0 0-1.319.717l-.895-.564a2.488 2.488 0 0 1 2.182-1.06zM23.29 13.52a.79.79 0 0 0 .337.662c.223.176.5.269.785.263.429-.001.84-.17 1.146-.472.305-.286.478-.685.478-1.103a2.047 2.047 0 0 0-1.324-.374 1.716 1.716 0 0 0-1.03.294.883.883 0 0 0-.392.73zm9.286-3.75l-3.39 7.79h-1.048l1.281-2.728-2.224-5.062h1.103l1.612 3.885 1.569-3.885h1.097z"
            fill="#5F6368"
          ></path>
          <path
            d="M13.986 11.284c0-.308-.024-.616-.073-.92h-4.29v1.747h2.451a2.096 2.096 0 0 1-.9 1.373v1.134h1.464a4.433 4.433 0 0 0 1.348-3.334z"
            fill="#4285F4"
          ></path>
          <path
            d="M9.629 15.721a4.352 4.352 0 0 0 3.01-1.097l-1.466-1.14a2.752 2.752 0 0 1-4.094-1.44H5.577v1.17a4.53 4.53 0 0 0 4.052 2.507z"
            fill="#34A853"
          ></path>
          <path
            d="M7.079 12.05a2.709 2.709 0 0 1 0-1.735v-1.17H5.577a4.505 4.505 0 0 0 0 4.075l1.502-1.17z"
            fill="#FBBC04"
          ></path>
          <path
            d="M9.629 8.44a2.452 2.452 0 0 1 1.74.68l1.3-1.293a4.37 4.37 0 0 0-3.065-1.183 4.53 4.53 0 0 0-4.027 2.5l1.502 1.171a2.715 2.715 0 0 1 2.55-1.875z"
            fill="#EA4335"
          ></path>
        </svg>

        <svg
          class="icon icon--full-color"
          viewBox="0 0 38 24"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          width="38"
          height="24"
          aria-labelledby="pi-master"
        >
          <title id="pi-master">Mastercard</title>
          <path
            opacity=".07"
            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
          ></path>
          <path
            fill="#fff"
            d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
          ></path>
          <circle fill="#EB001B" cx="15" cy="12" r="7"></circle>
          <circle fill="#F79E1B" cx="23" cy="12" r="7"></circle>
          <path
            fill="#FF5F00"
            d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"
          ></path>
        </svg>

        <svg
          class="icon icon--full-color"
          viewBox="0 0 38 24"
          xmlns="http://www.w3.org/2000/svg"
          width="38"
          height="24"
          role="img"
          aria-labelledby="pi-paypal"
        >
          <title id="pi-paypal">PayPal</title>
          <path
            opacity=".07"
            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
          ></path>
          <path
            fill="#fff"
            d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
          ></path>
          <path
            fill="#003087"
            d="M23.9 8.3c.2-1 0-1.7-.6-2.3-.6-.7-1.7-1-3.1-1h-4.1c-.3 0-.5.2-.6.5L14 15.6c0 .2.1.4.3.4H17l.4-3.4 1.8-2.2 4.7-2.1z"
          ></path>
          <path
            fill="#3086C8"
            d="M23.9 8.3l-.2.2c-.5 2.8-2.2 3.8-4.6 3.8H18c-.3 0-.5.2-.6.5l-.6 3.9-.2 1c0 .2.1.4.3.4H19c.3 0 .5-.2.5-.4v-.1l.4-2.4v-.1c0-.2.3-.4.5-.4h.3c2.1 0 3.7-.8 4.1-3.2.2-1 .1-1.8-.4-2.4-.1-.5-.3-.7-.5-.8z"
          ></path>
          <path
            fill="#012169"
            d="M23.3 8.1c-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.3-.1-.3-.1-.7-.1-1.1-.1h-3c-.1 0-.2 0-.2.1-.2.1-.3.2-.3.4l-.7 4.4v.1c0-.3.3-.5.6-.5h1.3c2.5 0 4.1-1 4.6-3.8v-.2c-.1-.1-.3-.2-.5-.2h-.1z"
          ></path>
        </svg>

        <svg
          class="icon icon--full-color"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          viewBox="0 0 38 24"
          width="38"
          height="24"
          aria-labelledby="pi-shopify_pay"
        >
          <title id="pi-shopify_pay">Shop Pay</title>
          <path
            opacity=".07"
            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
            fill="#000"
          ></path>
          <path
            d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32z"
            fill="#5A31F4"
          ></path>
          <path
            d="M21.382 9.713c0 1.668-1.177 2.858-2.821 2.858h-1.549a.133.133 0 00-.12.08.127.127 0 00-.01.049v2.192a.129.129 0 01-.13.129h-1.084a.13.13 0 01-.13-.13V6.986a.127.127 0 01.08-.12.129.129 0 01.05-.01h2.9c1.637 0 2.814 1.19 2.814 2.858v-.001zm-1.352 0c0-.958-.658-1.658-1.55-1.658h-1.468a.13.13 0 00-.13.13v3.05a.127.127 0 00.038.092.129.129 0 00.092.038h1.468c.892.005 1.55-.695 1.55-1.652zm1.674 3.791a1.527 1.527 0 01.647-1.317c.423-.316 1.084-.48 2.055-.514l1.033-.036v-.303c0-.607-.41-.863-1.068-.863-.658 0-1.075.231-1.17.61a.127.127 0 01-.125.09h-1.022a.13.13 0 01-.126-.092.125.125 0 01-.004-.055c.152-.898.904-1.58 2.494-1.58 1.692 0 2.303.783 2.303 2.276v3.172a.13.13 0 01-.132.129h-1.03a.13.13 0 01-.13-.13v-.236a.096.096 0 00-.061-.091.1.1 0 00-.107.022c-.31.334-.808.575-1.607.575-1.175 0-1.95-.607-1.95-1.657zm3.735-.687v-.246l-1.339.07c-.705.036-1.115.326-1.115.816 0 .444.376.69 1.034.69.893 0 1.42-.48 1.42-1.33zm2.316 4.6v-.919a.13.13 0 01.049-.1.132.132 0 01.108-.027c.158.029.318.044.479.044a1.229 1.229 0 001.245-.876l.067-.211a.133.133 0 000-.088l-2.145-5.471a.13.13 0 01.06-.165.13.13 0 01.062-.015h1.04a.132.132 0 01.123.085l1.456 3.859a.131.131 0 00.125.088.133.133 0 00.125-.088l1.265-3.848a.13.13 0 01.126-.09h1.076a.134.134 0 01.132.116.134.134 0 01-.008.063l-2.295 6.076c-.528 1.413-1.433 1.773-2.43 1.773a1.959 1.959 0 01-.561-.066.132.132 0 01-.1-.14h.001zM8.57 6.4a5.363 5.363 0 00-3.683 1.427.231.231 0 00-.029.31l.618.839a.236.236 0 00.362.028 3.823 3.823 0 012.738-1.11c2.12 0 3.227 1.584 3.227 3.15 0 1.7-1.163 2.898-2.835 2.921-1.292 0-2.266-.85-2.266-1.974a1.908 1.908 0 01.713-1.48.231.231 0 00.033-.324l-.65-.815a.236.236 0 00-.339-.034 3.43 3.43 0 00-.942 1.183 3.39 3.39 0 00-.337 1.47c0 1.935 1.655 3.452 3.775 3.464h.03c2.517-.032 4.337-1.884 4.337-4.415 0-2.247-1.667-4.64-4.752-4.64z"
            fill="#fff"
          ></path>
        </svg>

        <svg
          class="icon icon--full-color"
          viewBox="0 0 38 24"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          width="38"
          height="24"
          aria-labelledby="pi-visa"
        >
          <title id="pi-visa">Visa</title>
          <path
            opacity=".07"
            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
          ></path>
          <path
            fill="#fff"
            d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
          ></path>
          <path
            d="M28.3 10.1H28c-.4 1-.7 1.5-1 3h1.9c-.3-1.5-.3-2.2-.6-3zm2.9 5.9h-1.7c-.1 0-.1 0-.2-.1l-.2-.9-.1-.2h-2.4c-.1 0-.2 0-.2.2l-.3.9c0 .1-.1.1-.1.1h-2.1l.2-.5L27 8.7c0-.5.3-.7.8-.7h1.5c.1 0 .2 0 .2.2l1.4 6.5c.1.4.2.7.2 1.1.1.1.1.1.1.2zm-13.4-.3l.4-1.8c.1 0 .2.1.2.1.7.3 1.4.5 2.1.4.2 0 .5-.1.7-.2.5-.2.5-.7.1-1.1-.2-.2-.5-.3-.8-.5-.4-.2-.8-.4-1.1-.7-1.2-1-.8-2.4-.1-3.1.6-.4.9-.8 1.7-.8 1.2 0 2.5 0 3.1.2h.1c-.1.6-.2 1.1-.4 1.7-.5-.2-1-.4-1.5-.4-.3 0-.6 0-.9.1-.2 0-.3.1-.4.2-.2.2-.2.5 0 .7l.5.4c.4.2.8.4 1.1.6.5.3 1 .8 1.1 1.4.2.9-.1 1.7-.9 2.3-.5.4-.7.6-1.4.6-1.4 0-2.5.1-3.4-.2-.1.2-.1.2-.2.1zm-3.5.3c.1-.7.1-.7.2-1 .5-2.2 1-4.5 1.4-6.7.1-.2.1-.3.3-.3H18c-.2 1.2-.4 2.1-.7 3.2-.3 1.5-.6 3-1 4.5 0 .2-.1.2-.3.2M5 8.2c0-.1.2-.2.3-.2h3.4c.5 0 .9.3 1 .8l.9 4.4c0 .1 0 .1.1.2 0-.1.1-.1.1-.1l2.1-5.1c-.1-.1 0-.2.1-.2h2.1c0 .1 0 .1-.1.2l-3.1 7.3c-.1.2-.1.3-.2.4-.1.1-.3 0-.5 0H9.7c-.1 0-.2 0-.2-.2L7.9 9.5c-.2-.2-.5-.5-.9-.6-.6-.3-1.7-.5-1.9-.5L5 8.2z"
            fill="#142688"
          ></path>
        </svg> */}
      </div>
      {/* </div> */}
      {/* <div> */}
       <p className="footer-copy">&copy; 2023, Hooplivestats</p>
      {/* </div> */}
    </>
  );
};

export default Footer;
