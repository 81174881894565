import React from "react";
import { Col, Row } from "react-bootstrap";
import headerSection from "../../assets/images/headerSection.jpg";
import StoryCard from "../../Components/StoryCard/StoryCard";
// import { getNewsArticles } from "../../Store/actions/HomeAction";
import { useOnMount } from "../../utils/helpers";
import {
  getPricingArticles,
  getStoryArticles,
} from "../../Store/actions/HomeAction";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../utils/constants";
import "./Story.scss";
// import headerSection from "../../assets/images/headerSection.jpg";

const Pricing = () => {
  const dispatch = useDispatch();
  const { pricingListArticle } = useSelector((state) => state.home);
  useOnMount(() => {
    // dispatch(getFeature());
    dispatch(getPricingArticles());
  });
 
  return (
    <div className="new-article">
      {/* <img className="header-section" src={headerSection} alt="header-poster" /> */}
      {/* <Row className="align-items-center justify-content-between mx-0"> */}
      {/* <Col md={12}> */}
      {/* <div className="header-contain border-bottom text-center">
            <div className="header">News</div>
            <div className="sub-header">News</div>
          </div> */}
      <div>
        <h2 className="title-head">Pricing</h2>
      </div>
      {/* </Col> */}
      {/* </Row> */}
      {/* <Row className="mx-0"> */}
      <div
        style={{
          color: "white",
          textAlign: "center",
          display: "flex",
          alignItems: "baseline",
          gap: 100,
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {pricingListArticle &&
          pricingListArticle.length > 0 &&
          pricingListArticle.map((val) => (
            <>
              <div>
                <span style={{ fontSize: "20px" }}>{val.title}</span>
                <h4>{val.rate} per team</h4>
              </div>
            </>
          ))}
      </div>
    </div>
  );
};

export default Pricing;
